<template>
  <div>
    <v-card>
      <v-card-title>
        <v-btn @click="accept" color="primary">
          Join Project Team
        </v-btn>
      </v-card-title>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import {mapState} from "vuex";

export default {
  methods: {
    async accept() {
      try {
        const token = this.$route.params.token
        const response = await axios.get('project/accept-invite/' + token)

        await this.$store.dispatch('addAlertMsg', {
          type: 'success',
          text: 'You joined the team, congrats!'
        })

        await this.$store.dispatch('fetchProjects')
        await this.$store.dispatch('setCurrentProjectById', response.data.project_id)
        await this.$router.push({ name: 'dashboard' })

        await this.$store.dispatch('addAlertMsg', {
          type: 'success',
          text: 'Welcome in project %1'.replace('%1', this.currentProject.name)
        })
      } catch (e) {
        alert(e)
        await this.$router.push({ name: 'dashboard' })
      }
    }
  },
  computed: {
    ...mapState([
      'currentProject',
    ]),
  },
}
</script>
